import { createMachine, assign, fromPromise } from "xstate";
// import checkSupabaseAuthLogin from "../services/checkSupabaseAuthLogin";
import checkAccessTokenValidity from "../services/checkAccessTokenValidity";
import generateAuthCode from "../services/generateAuthCode";
import generateAccessTokenAndRefreshToken from "../services/generateAccessTokenAndRefreshToken";
import saveAccessTokenAndRefreshTokenInSupabase from "../services/saveAccessTokenAndRefreshTokenInSupabase";
import refreshAccessToken from "../services/refreshAccessToken";
import saveAccessTokenInSupabase from "../services/saveAccessTokenInSupabase";
import logoutAndRemoveLocalTokens from "../services/logoutAndRemoveLocalTokens";
import checkAccessTokenInSupabase from "../services/checkAccessTokenInSupabase";
import { supabase } from "../../utils/supabase";

console.log({
    id: process.env.REACT_APP_FYERS_APP_ID
})

const supabaseAuthMachine = createMachine({
    id: "supabaseAuth",
    initial: "checkingSupabaseAuthLogin",
    context: {
        supabase: {},
        authCode: null,
        access: null,
        refresh: null,
        fyers: {}
    },
    states: {
        checkingSupabaseAuthLogin: {
            invoke: {
                src: fromPromise(() => new Promise((resolve, reject) => {
                    supabase.auth.getSession().then(data => {
                        console.log("session", data)
                        if (data?.data?.session?.access_token !== null) {
                            resolve({ data: data, error: '' })
                        } else {
                            reject({ data: data?.data, error: 'no session found' })
                        }
                    })
                })
                ),
                onDone: {
                    target: "hasSupabaseAuthLogin",
                    actions: assign({
                        supabase: (context, event) => context.event.output.data.data.session
                    })
                },
                onError: {
                    target: 'logout'
                },
            },
        },
        supasbeLoggedIn: {
            on: {
                'supabaseLoggedIn': {
                    actions: assign({
                        supabase: (context, event) => event.data.data.session
                    })
                }
            },
            onDone: {
                target: 'hasSupabaseAuthLogin'
            },
            onError: {
                target: 'logout'
            }
        },
        hasSupabaseAuthLogin: {
            invoke: {
                src: checkAccessTokenInSupabase,
                onDone: {
                    target: "hasFyersAccessToken",
                    actions: assign({
                        access: (context, event) => ({ token: context.event.output.access_token, expiry: context.event.output.access_expiry }),
                        refresh: (context, event) => ({ token: context.event.output.refresh_token, expiry: context.event.output.refresh_expiry }),
                    }),
                },
                onError: {
                    target: "generateAuthCode",
                    // target: "logout",
                    actions: (context) => console.error(context.event.error)
                },
            },
        },
        generateAuthCode: {
            invoke: {
                src: generateAuthCode,
                onDone: {
                    target: "waitForAppSignal",
                    actions: assign({
                        authCode: (context, event) => context.event.output,
                    }),
                },
                onError: {
                    target: "logout",
                    actions: (context) => console.error(context.event.error)
                },
            },
        },
        waitForAppSignal: {
            on: {
                AUTH_CODE_RECEIVED: {
                    actions: assign({
                        authCode: (context, event) => context.event.data.auth_code,
                    }),
                    target: "receiveAuthCode",
                    guard: (context, event) => !!context.context.authCode
                },
            },
        },
        receiveAuthCode: {
            invoke: {
                src: generateAccessTokenAndRefreshToken,
                input: (context, event) => ({ authCode: context.context.authCode }),
                onDone: {
                    target: "saveAccessTokenAndRefreshToken",
                    actions: assign({
                        access: (context, event) => ({ token: context.event.output.access_token, expiry: context.event.output.access_expiry }),
                        refresh: (context, event) => ({ token: context.event.output.refresh_token, expiry: context.event.output.refresh_expiry }),
                    }),
                },
                onError: {
                    target: "logout",
                },
            },
        },
        hasFyersAccessToken: {
            invoke: {
                src: checkAccessTokenValidity,
                // input: (context, event) => console.log('input', { context, event }),
                input: (context, event) => ({ accessToken: context.context.access.token }),
                onDone: {
                    target: "dashboard",
                    // cond: (context, event) => event.data,
                },
                onError: {
                    target: "refreshAccess",
                    // target: "logout",
                },
            },
        },
        refreshAccess: {
            invoke: {
                src: refreshAccessToken,
                input: (context, event) => ({ token: context.context.refresh.token }),
                onDone: {
                    target: "saveAccessToken",
                    actions: assign({
                        access: (context, event) => ({ token: context.event.output.token, expiry: context.event.output.expiry }),
                    }),
                },
                onError: {
                    target: "logout",
                },
            },
        },
        saveAccessToken: {
            invoke: {
                src: saveAccessTokenInSupabase,
                input: (context, event) => ({ access: { token: context.context.access.token, expiry: context.context.access.expiry } }),
                onDone: {
                    target: "dashboard",
                },
                onError: {
                    target: "logout",
                },
            },
        },
        saveAccessTokenAndRefreshToken: {
            invoke: {
                src: saveAccessTokenAndRefreshTokenInSupabase,
                input: (context, event) => ({ access: { token: context.context.access.token, expiry: context.context.access.expiry }, refresh: { token: context.context.refresh.token, expiry: context.context.refresh.expiry } }),
                onDone: {
                    target: "dashboard",
                },
                onError: {
                    target: "logout",
                },
            },
        },
        dashboard: {
            type: "final",
        },
        logout: {
            type: "final",
            entry: logoutAndRemoveLocalTokens,
        },
    },
});

export default supabaseAuthMachine;

/**






*/
