import React, { useEffect, useMemo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { logoutFyers } from ".";
import ClipboardJS from "clipboard";

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export default function FyersRedirect() {
  const query = useQuery();
  const navigate = useNavigate();
  const clipboard = new ClipboardJS(".btn");

  clipboard.on("success", function (e) {
    alert("Copied");
  });

  useEffect(() => {
    localStorage.setItem("FYERS_AUTH_CODE", query.get("auth_code"));
    console.log(query.get("auth_code"));

    const formData = new FormData();
    formData.append("auth_code", query.get("auth_code"));
  }, []);

  return (
    <div className='w-screen h-screen overflow-hidden flex flex-col items-center justify-center gap-4'>
      <button
        className='btn btn-success'
        data-clipboard-text={query.get("auth_code")}>
        Copy Auth Code
      </button>
    </div>
  );
}
