import React, { Suspense } from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import Home from '../components/home'
import FyersRedirect from '../fyers/redirect'
import Dashboard from '../components/dashboard'
import Orders from '../components/Orders'

import PrivateRoutes from './PrivateRoutes'
import { ChartPage } from '../components/Chart'
import Sockets from '../components/sockets'
import Positions from '../components/Positions'


export default function AppRouter() {
    return (
        <Router>
            <Suspense
                fallback={<div className='w-screen h-screen overflow-hidden flex items-center justify-center'>
                    <span className="loading loading-infinity loading-lg"></span>
                </div>}
            ></Suspense>
            <Routes>
                {/* <Route path="/" element={<Home />} /> */}
                <Route path="/api-login/redirect" element={<FyersRedirect />} />
                <Route element={<PrivateRoutes />}>
                    <Route path='/' element={<Dashboard />} />
                    <Route path="/orders" element={<Orders />} />
                    <Route path="/position" element={<Positions />} />
                    <Route path="/chart" element={<ChartPage />} />
                    <Route path="/chart/:id" element={<ChartPage />} />
                    <Route path="/sockets" element={<Sockets />} />
                </Route>
            </Routes>
        </Router>
    )
}
