import React from "react";
import { Link } from "react-router-dom";

export default function Navbar() {
  return (
    <div className='navbar bg-base-100 border-b border-gray-100 mb-10'>
      <div className='flex-1'>
        <Link to='/' className='btn btn-ghost text-xl'>
          Trade
        </Link>
      </div>
      <div className='flex-none'>
        <ul className='menu menu-horizontal px-1'>
          <li>
            <Link to='/position'>Positions</Link>
          </li>
          <li>
            <Link to='/orders'>Orders</Link>
          </li>
          <li>
            <Link to='/account'>Account</Link>
          </li>
          <li>
            <Link to='/logout'>Logout</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
