import { fromPromise } from "xstate";
import fyers from "../../fyers";
import { generateAccessTokenExpiry, generateRefreshTokenExpiry } from "./generateTokenExpiry";


const generateAccessTokenAndRefreshToken = fromPromise(({ input }) => new Promise(async (resolve, reject) => {
    let resp_object
    console.log({ input })
    try {
        resp_object = await fyers.generate_access_token({
            auth_code: input.authCode,
            client_id: process.env.REACT_APP_FYERS_APP_ID,
            secret_key: process.env.REACT_APP_FYERS_APP_SECRET_KEY
        })
        console.log({ resp_object })
    } catch (error) {
        reject(error)
    }
    resolve({ access_token: resp_object.access_token, access_expiry: generateAccessTokenExpiry(), refresh_token: resp_object.refresh_token, refresh_expiry: generateRefreshTokenExpiry() })
}))

export default generateAccessTokenAndRefreshToken;
