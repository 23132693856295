import { fyersModel } from "fyers-web-sdk-v3";

const fyers = new fyersModel({
    AccessToken: "",
    AppID: process.env.REACT_APP_FYERS_APP_ID,
    RedirectURL: process.env.REACT_APP_FYERS_REDIRECT_URI,
});

if (localStorage.getItem('FYERS_TOKEN')) {
    fyers.setAccessToken(localStorage.getItem("FYERS_TOKEN"));
}

export const logoutFyers = async () => {
    localStorage.removeItem("FYERS_TOKEN")
    localStorage.removeItem("Session")
    localStorage.removeItem("FYERS_DATETIME")
    localStorage.removeItem("FYERS_AUTH_CODE")
}


export default fyers;
