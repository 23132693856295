import { useEffect } from 'react';
import AppRouter from './routes';
import authActor from './xstate/actors/authActor';

function App() {
  useEffect(() => {
    authActor.start();
  })

  return (
    <AppRouter />
  );
}

export default App;
