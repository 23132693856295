import { fromPromise, log } from "xstate";
import { generateAccessTokenExpiry } from "./generateTokenExpiry";


const refreshAccessToken = fromPromise(({ input }) => new Promise((resolve, reject) => {

    fetch("https://api-t1.fyers.in/api/v3/validate-refresh-token", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            grant_type: 'refresh_token',
            appIdHash: process.env.REACT_APP_FYERS_ID_HASH,
            refresh_token: input.token,
            pin: process.env.REACT_APP_FYERS_PIN,
        }),
    })
        .then(response => response.json())
        .then(data => resolve({ token: data.access_token, expiry: generateAccessTokenExpiry() }))
        .catch(error => reject(error));
}))

export default refreshAccessToken;
