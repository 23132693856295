import React, { useEffect, useState } from "react";
import Navbar from "./navbar";
import fyers from "../fyers";
import authActor from "../xstate/actors/authActor";

export default function Positions() {
  const [pos, setPos] = useState("");
  useEffect(() => {
    console.log("HERE");

    fyers.setAccessToken(authActor.getSnapshot().context.access.token ?? "");
    fyers
      .get_positions()
      .then((response) => {
        console.log(response);
        setPos({ ...response });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div>
      <Navbar />
      Positions
      <div className='overflow-x-auto'>
        <table className='table'>
          {/* head */}
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Job</th>
              <th>Favorite Color</th>
            </tr>
          </thead>
          <tbody>
            {/* row 1 */}
            <tr>
              <th>1</th>
              <td>Cy Ganderton</td>
              <td>Quality Control Specialist</td>
              <td>Blue</td>
            </tr>
            {/* row 2 */}
            <tr>
              <th>2</th>
              <td>Hart Hagerty</td>
              <td>Desktop Support Technician</td>
              <td>Purple</td>
            </tr>
            {/* row 3 */}
            <tr>
              <th>3</th>
              <td>Brice Swyre</td>
              <td>Tax Accountant</td>
              <td>Red</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}
