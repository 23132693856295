import React, { useEffect, useState } from "react";
import Navbar from "./navbar";
import { fyers, initializeFyers, logoutFyers } from "../fyers";

export default function Orders(obj) {
  const extractData = (data) => {
    const filteredData = {};
    const originalKeys = [
      "id",
      "exchOrdId",
      "qty",
      "filledQty",
      "tradedPrice",
      "symbol",
      "instrument",
      "orderDateTime",
      "orderValidity",
      "ex_sym",
      "description",
    ];
    originalKeys.forEach((key) => {
      if (data.hasOwnProperty(key)) {
        filteredData[key] = data[key];
      }
    });

    return { ...filteredData };
  };

  const [orders, setOrders] = useState();
  useEffect(() => {
    // initializeFyers(fyers);
    // fyers
    //   .get_orders()
    //   .then((response) => {
    //     setOrders(
    //       response.orderBook.length > 0
    //         ? response.orderBook.map((e) => ({ ...extractData(e) }))
    //         : response.orderBook
    //     );
    //   })
    //   .catch((error) => {
    //     logoutFyers();
    //     console.log(error);
    //   });
  }, []);
  return (
    <div>
      <Navbar />
      Orders:
      <pre>
        <code>{JSON.stringify(orders, null, 2)}</code>
      </pre>
    </div>
  );
}
