import { fromPromise } from "xstate";
import fyers from "../../fyers";

const generateAuthCode = fromPromise(() => new Promise((resolve, reject) => {
    let authCode
    try {
        authCode = fyers.generateAuthCode({
            client_id: process.env.REACT_APP_FYERS_APP_ID,
            redirect_uri: process.env.REACT_APP_FYERS_REDIRECT_URI,
        });
        console.log(authCode)
        resolve(authCode)
    } catch (error) {
        reject(error);
    }
}));

export default generateAuthCode;
