import { Outlet } from "react-router-dom";
import Home from "../components/home";
import authActor from "../xstate/actors/authActor";
import { useEffect, useState } from "react";
import Auth from "../components/auth";

const PrivateRoutes = () => {
  const [status, setStatus] = useState(authActor.getSnapshot().value);
  const [authCode, setAuthCode] = useState();

  useEffect(() => {
    const subscription = authActor.subscribe((snapshot) => {
      setStatus(snapshot.value);
    });

    return () => subscription.unsubscribe();
  }, []);

  switch (status) {
    case "dashboard":
      return <Outlet />;
    case "logout":
      return <Auth />;
    default:
      return (
        <div className='w-screen h-screen overflow-hidden flex flex-col items-center justify-center gap-4'>
          <span className='loading loading-infinity loading-lg'></span>
          <input
            type='text'
            placeholder='Auth Code Goes here'
            onChange={(e) => setAuthCode(e.target.value)}
            className='input input-bordered w-full max-w-xs'
          />
          <button
            onClick={() =>
              authActor.send({
                type: "AUTH_CODE_RECEIVED",
                data: { auth_code: authCode },
              })
            }
            className='inline-flex justify-center rounded-lg text-sm font-semibold py-3 px-4 bg-slate-900 text-white hover:bg-slate-700'>
            Got Auth Code
          </button>
        </div>
      );
  }
};

export default PrivateRoutes;
