import { fromPromise } from "xstate";
import { supabase } from "../../utils/supabase";


const checkAccessTokenInSupabase = fromPromise(() => new Promise(async (resolve, reject) => {
    let { data: tokens, error } = await supabase.from("tokens").select('*').eq('id', process.env.REACT_APP_FYERS_APP_ID);
    console.log({ tokens });

    if (error) {
        reject(error);
    }

    if (tokens[0] && tokens[0].access_token) {
        resolve({ ...tokens[0] })
    } else {
        reject('no access token present in supabase')
    }
}));

export default checkAccessTokenInSupabase;
