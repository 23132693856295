import React, { Fragment, useState } from "react";
import { supabase } from "../utils/supabase";
import authActor from "../xstate/actors/authActor";
import { assign } from "xstate";

export default function Auth() {
  const [mail, setMail] = useState("me@adtjha.in");
  const [pass, setPass] = useState();
  const [Load, setLoad] = useState(false);

  const handleSubmit = async () => {
    if (mail !== "" && pass !== "") {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: mail,
        password: pass,
      });
      console.log(data, error);
      authActor.send({
        type: "supabaseLoggedIn",
        actions: [
          assign({
            session: data.session,
          }),
        ],
      });
    }
  };

  return (
    <div className='w-screen h-screen overflow-hidden flex flex-col items-center justify-center gap-4'>
      {Load ? (
        <span className='loading loading-infinity loading-lg'></span>
      ) : (
        <Fragment>
          <label className='input input-bordered flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 16 16'
              fill='currentColor'
              className='w-4 h-4 opacity-70'>
              <path d='M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z' />
              <path d='M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z' />
            </svg>
            <input
              type='text'
              onChange={(e) => setMail(e.target.value)}
              value={mail}
              className='grow'
              placeholder='Email'
            />
          </label>
          <label className='input input-bordered flex items-center gap-2'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 16 16'
              fill='currentColor'
              className='w-4 h-4 opacity-70'>
              <path d='M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z' />
              <path d='M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z' />
            </svg>
            <input
              type='password'
              onChange={(e) => setPass(e.target.value)}
              value={pass}
              className='grow'
              placeholder='Password'
            />
          </label>
        </Fragment>
      )}

      <button className='btn' onClick={handleSubmit}>
        Login
      </button>
    </div>
  );
}
