import { supabase } from "../../utils/supabase";


const logoutAndRemoveLocalTokens = async () => {
    await supabase.auth.getUser();
    // Remove local tokens
    localStorage.removeItem('fyers_access_token');
    localStorage.removeItem('fyers_refresh_token');
};

export default logoutAndRemoveLocalTokens
