import React, { Fragment, useEffect, useState } from "react";
import Navbar from "./navbar";
import { useNavigate } from "react-router-dom";
import authActor from "../xstate/actors/authActor";
import fyers from "../fyers";
import { fyersDataSocket } from "fyers-web-sdk-v3";

export default function Dashboard() {
  const inp = [
    "NSE:NIFTY50-INDEX",
    "NSE:NIFTYBANK-INDEX",
    "NSE:FINNIFTY-INDEX",
    "NSE:MIDCPNIFTY-INDEX",
  ];

  const navigate = useNavigate();

  const [skt, setSocket] = useState();

  const [loading, setLoading] = useState(false);

  const [line, setLine] = useState({
    type: "r",
    value: "",
    scrip: "NSE:NIFTY50-INDEX",
  });

  const [topCardsArray, setTopCardsArray] = useState([]);

  const handleCreate = () => {
    console.log("Create", line);
    setLoading(true);
  };

  useEffect(() => {
    fyers.setAccessToken(authActor.getSnapshot().context.access.token ?? "");
    fyers
      .getQuotes(inp)
      .then((response) => {
        let data = response.d;
        console.log(data);
        setTopCardsArray(data.map((e) => ({ ...e.v })));
      })
      .catch(console.error);

    setSocket(
      fyersDataSocket.getInstance(
        `${process.env.REACT_APP_FYERS_APP_ID}:${
          authActor.getSnapshot().context.access.token ?? ""
        }`
      )
    );
  }, []);

  useEffect(() => {
    try {
      skt?.on("connect", function () {
        skt.subscribe(inp, false, 1);
        skt.mode(skt.FullMode, 1);
        console.log(skt.isConnected());
        // skt.mode(skt.LiteMode,[11,12,13])
        // skt.channelresume(2)
        // skt.unsubscribe(['NSE:IDEA-EQ'],false,1)
      });

      const setValue = (message) =>
        setTopCardsArray([
          ...topCardsArray.map((e) => {
            if (e.description === message.symbol) {
              e.lp = message.ltp;
              e.open_price = message.open_price;
            }
            return e;
          }),
        ]);

      skt?.on("message", function (message) {
        if (message.type !== "cn") {
          setValue(message);
        }
        console.log("m", { TEST: message });
      });

      skt?.on("error", function (message) {
        console.log("erroris", message);
      });

      skt?.on("close", function () {
        console.log("socket closed");
      });

      skt?.connect();
      skt?.autoreconnect();
    } catch (error) {
      console.error(error);
    }
  }, [inp]);

  return (
    <div className='w-screen h-screen overflow-hidden flex flex-col items-start justify-start'>
      <Navbar />
      <div className='lg:mx-auto w-[100%] lg:w-fit m-[0rem_1rem] stats stats-vertical lg:stats-horizontal shadow'>
        {topCardsArray.map((e) => (
          <div
            className='stat hover:bg-gray-200 cursor-pointer'
            onClick={() => navigate(`/chart/${e.original_name}`)}>
            <div className='stat-title'>{e.description}</div>
            <div className='stat-value'>{e.lp.toFixed(2)}</div>
            {e.open_price > 0 && e.lp > 0 ? (
              <div
                className={`stat-desc font-bold ${
                  e.lp > e.open_price ? "text-green-500" : "text-red-500"
                }`}>
                {e.lp < e.open_price
                  ? `↘︎ ${(e.open_price - e.lp).toFixed(2)} (${(
                      ((e.open_price - e.lp) / e.open_price) *
                      100
                    ).toFixed(2)}%)`
                  : `↗︎ ${(e.lp - e.open_price).toFixed(2)} (${(
                      ((e.lp - e.open_price) / e.open_price) *
                      100
                    ).toFixed(2)}%)`}
              </div>
            ) : (
              ""
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
