// Create an actor that you can send events to.

import { createActor } from "xstate";
import supabaseAuthMachine from "../machines/authMachine";

// Note: the actor is not started yet!
const authActor = createActor(supabaseAuthMachine);


// Subscribe to snapshots (emitted state changes) from the actor
authActor.subscribe((snapshot) => {
    console.log('Value:', snapshot.value);
});

export default authActor
