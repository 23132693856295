import React from "react";
import { io } from "socket.io-client";
import { fyersOrderSocket } from "fyers-web-sdk-v3";

// const socket = io();

// const fyersOrderdata = new fyersOrderSocket(
//   `${process.env.REACT_APP_FYERS_APP_ID}:${localStorage.getItem("FYERS_TOKEN")}`
// );

// fyersOrderdata.on("error", function (errmsg) {
//   console.log(errmsg);
// });

// //for ticks of general data like price-alerts,EDIS
// fyersOrderdata.on("general", function (msg) {
//   console.log(msg);
// });
// fyersOrderdata.on("connect", function () {
//   fyersOrderdata.subscribe([
//     fyersOrderdata.orderUpdates,
//     fyersOrderdata.tradeUpdates,
//     fyersOrderdata.positionUpdates,
//     fyersOrderdata.edis,
//     fyersOrderdata.pricealerts,
//   ]);
// });

// fyersOrderdata.on("close", function () {
//   console.log("closed");
// });

// //for ticks of orderupdates
// fyersOrderdata.on("orders", function (msg) {
//   console.log("orders", msg);
// });

// //for ticks of tradebook
// fyersOrderdata.on("trades", function (msg) {
//   console.log("trades", msg);
// });

// //for ticks of positions
// fyersOrderdata.on("positions", function (msg) {
//   console.log("positions", msg);
// });
// fyersOrderdata.autoreconnect();
// fyersOrderdata.connect();

// ------------------------------------------------------------------------------------------------------------------------------------------
// Sample Success Response
// ------------------------------------------------------------------------------------------------------------------------------------------

//   {
//     "s":"ok",
//     "orders":{
//         "clientId":"XV20986",
//         "id":"23080400089344",
//         "exchOrdId":"1100000009596016",
//         "qty":1,
//         "filledQty":1,
//         "limitPrice":7.95,
//         "type":2,
//         "fyToken":"101000000014366",
//         "exchange":10,
//         "segment":10,
//         "symbol":"NSE:IDEA-EQ",
//         "instrument":0,
//         "offlineOrder":false,
//         "orderDateTime":"04-Aug-2023 10:12:58",
//         "orderValidity":"DAY",
//         "productType":"INTRADAY",
//         "side":-1,
//         "status":90,
//         "source":"W",
//         "ex_sym":"IDEA",
//         "description":"VODAFONE IDEA LIMITED",
//         "orderNumStatus":"23080400089344:2"
//     }
//   }

const Sockets = () => {
  return <div>Check Sockets.</div>;
};

export default Sockets;
