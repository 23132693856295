import { fromPromise } from "xstate";
import { supabase } from "../../utils/supabase";


const saveAccessTokenAndRefreshTokenInSupabase = fromPromise(({ input }) => new Promise(async (resolve, reject) => {
    let res;
    try {
        res = await supabase
            .from('tokens')
            .upsert({ id: process.env.REACT_APP_FYERS_APP_ID, access_token: input.access.token, access_expiry: input.access.expiry, refresh_token: input.refresh.token, refresh_expiry: input.refresh.expiry })
            .eq('id', process.env.REACT_APP_FYERS_APP_ID)
            .select()
    } catch (error) {
        reject(error)
    }
    resolve(res)
}))

export default saveAccessTokenAndRefreshTokenInSupabase;
