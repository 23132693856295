export const generateAccessTokenExpiry = () => {
    const now = new Date();
    const expiry = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours from now
    return expiry.toISOString();
}

export const generateRefreshTokenExpiry = () => {
    const now = new Date();
    const expiry = new Date(now.getTime() + 15 * 24 * 60 * 60 * 1000); // 15 days from now
    return expiry.toISOString();
}
