import { fromPromise } from "xstate";
import fyers from "../../fyers";


const checkAccessTokenValidity = fromPromise(({ input }) => new Promise(async (resolve, reject) => {
    console.log({ input });

    try {
        fyers.setAccessToken(input.accessToken)
        console.log({ fyers })
        return fyers.get_funds().then((val) => {
            resolve({ val })
        }).catch((err) => { reject(err) })

    } catch (error) {
        reject(error);
    }
}))

export default checkAccessTokenValidity;
