
import { fromPromise } from 'xstate';
import { supabase } from '../../utils/supabase';

const saveAccessTokenInSupabase = fromPromise(({ input }) => supabase
    .from('tokens')
    .update({ access_token: input.access.token, access_expiry: input.access.expiry })
    .eq('id', process.env.REACT_APP_FYERS_APP_ID)
    .select())

export default saveAccessTokenInSupabase;
