import { createChart, ColorType } from "lightweight-charts";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import authActor from "../xstate/actors/authActor";
import fyers from "../fyers";

export const ChartComponent = (props) => {
  const {
    data,
    id,
    colors: {
      backgroundColor = "white",
      lineColor = "#2962FF",
      textColor = "black",
      areaTopColor = "#2962FF",
      areaBottomColor = "rgba(41, 98, 255, 0.28)",
    } = {},
  } = props;

  const chartContainerRef = useRef();

  const min_q = {
    "NSE:NIFTY50-INDEX": 25,
  };

  const handleTradeSignal = (e) => {
    console.log(e.target.id);
    let funds = 0,
      req = [],
      posibility = {};
    fyers.setAccessToken(authActor.getSnapshot().context.access.token ?? "");
    fyers
      .get_funds()
      .then(async (res) => {
        funds = res.fund_limit.filter((e) => e.title === "Available Balance")[0]
          .equityAmount;

        await new Promise((resolve, _) => setTimeout(resolve, 400));
        fyers
          .getOptionChain({
            symbol: id,
            strikecount: 1,
            timestamp: "",
          })
          .then((res) => {
            res.data.optionsChain.forEach((e) => {
              if (e.symbol.slice(-2) === "CE") {
                req.push({ ...e });
              }
            });

            console.log({ req });

            for (const r of req) {
              let quantity_possible = parseInt(funds / r["ltp"]);
              let quantity_buyable =
                parseInt(quantity_possible / min_q[id]) * min_q[id];
              let quantity_buyable_cost = parseInt(quantity_buyable) * r["ltp"];
              let fund_percenatage_usage = quantity_buyable_cost / funds;
              posibility[r.fyToken] = {
                quantity_possible,
                quantity_buyable,
                quantity_buyable_cost,
                fund_percenatage_usage,
                token: r["fyToken"],
              };
            }

            console.log(posibility);
          })
          .catch(console.error);
      })
      .catch(console.error);
  };

  useEffect(() => {
    const handleResize = () => {
      console.log({ chartContainerRef });
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };
    const chart = createChart(chartContainerRef.current, {
      layout: {
        textColor: "black",
        background: { type: "solid", color: "white" },
      },
      width: chartContainerRef.current.clientWidth,
      height: window.innerHeight,
    });
    chart.timeScale().fitContent();
    const newSeries = chart.addCandlestickSeries({
      upColor: "#26a69a",
      downColor: "#ef5350",
      borderVisible: false,
      wickUpColor: "#26a69a",
      wickDownColor: "#ef5350",
    });

    // chart.subscribeCrosshairMove((param) => {
    //   const x = param.point.x;
    //   const data = param.seriesData.get(newSeries);
    //   const price = data.value !== undefined ? data.value : data.close;
    //   const y = newSeries.priceToCoordinate(price);
    //   console.log(`The data point is at position: ${x}, ${y}`);
    // });

    chart.applyOptions({
      rightPriceScale: {
        visible: true,
      },
      leftPriceScale: {
        visible: true,
      },
    });

    chart.applyOptions({
      watermark: {
        visible: true,
        fontSize: 24,
        horzAlign: "center",
        vertAlign: "center",
        color: "rgba(171, 71, 188, 0.5)",
        text: "Shakal",
      },
    });

    const myPriceLine = {
      price: 23500,
      color: "#3179F5",
      lineWidth: 2,
      lineStyle: 2, // LineStyle.Dashed
      axisLabelVisible: true,
      title: "Oh label",
    };

    newSeries.createPriceLine(myPriceLine);

    const markers = [
      {
        time: { year: 2024, month: 9, day: 9 },
        position: "aboveBar",
        color: "#f68410",
        shape: "circle",
        text: "Marker",
      },
    ];
    newSeries.setMarkers(markers);
    newSeries.setData(data);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      chart.remove();
    };
  }, [
    data,
    backgroundColor,
    lineColor,
    textColor,
    areaTopColor,
    areaBottomColor,
  ]);
  return (
    <div>
      <div className='z-[500] absolute top-0 left-0 right-0 bg-base-300 border bg-transparent flex flex-row items-center justify-end p-4 w-[80%] gap-4'>
        <button
          id={"buy"}
          onClick={handleTradeSignal}
          className='btn btn-lg btn-outline btn-success'>
          Buy
        </button>
        <button
          id={"sell"}
          onClick={handleTradeSignal}
          className='btn btn-lg btn-outline btn-error'>
          Sell
        </button>
      </div>
      <div ref={chartContainerRef} />
    </div>
  );
};

const initialData = [
  { open: 10, high: 10.63, low: 9.49, close: 9.55, time: 1642427876 },
  { open: 9.55, high: 10.3, low: 9.42, close: 9.94, time: 1642514276 },
  { open: 9.94, high: 10.17, low: 9.92, close: 9.78, time: 1642600676 },
  { open: 9.78, high: 10.59, low: 9.18, close: 9.51, time: 1642687076 },
  { open: 9.51, high: 10.46, low: 9.1, close: 10.17, time: 1642773476 },
  { open: 10.17, high: 10.96, low: 10.16, close: 10.47, time: 1642859876 },
  { open: 10.47, high: 11.39, low: 10.4, close: 10.81, time: 1642946276 },
  { open: 10.81, high: 11.6, low: 10.3, close: 10.75, time: 1643032676 },
  { open: 10.75, high: 11.6, low: 10.49, close: 10.93, time: 1643119076 },
  { open: 10.93, high: 11.53, low: 10.76, close: 10.96, time: 1643205476 },
];

export function ChartPage(props) {
  const { id } = useParams();
  const [data, setData] = useState(initialData);
  const [LastTime, setLastTime] = useState();

  useEffect(() => {
    console.log(id);
    let inp = {
      symbol: id,
      resolution: "5",
      date_format: "1",
      range_from: DateTime.now().minus({ days: 10 }).toISODate(),
      range_to: DateTime.now().toISODate(),
      cont_flag: "1",
      oi_flag: "1",
    };
    fyers.setAccessToken(authActor.getSnapshot().context.access.token ?? "");
    fyers
      .getHistory(inp)
      .then((response) => {
        console.log(response, inp);
        let d = {};
        response.candles.forEach((e) => {
          d[e[0]] = {
            time: e[0],
            open: e[1],
            high: e[2],
            low: e[3],
            close: e[4],
            volume: e[5],
          };
        });
        setData([...Object.values(d)]);
        console.log([...Object.values(d)]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return <ChartComponent {...props} id={id} data={data}></ChartComponent>;
}
